<template>
<div>
  <b-container>
    <div v-if="loading" class="text-primary font-size-22 d-flex justify-content-center align-items-end p-5">
      <spinner-loading text="loading"/>
    </div>
    <div v-else>
      <div v-if="status">
        <p class="text-primary pt-5 font-size-28 text-center font-size-22 font-weight-bold">{{$t('userProfile.successPayment')}}</p>
        <div  class="d-flex justify-content-center align-items-center">
          <img :src="require('@/assets/animation/successPayment.svg')" class="w-300px" />
        </div>
      </div>
      <div v-else-if="!status">
        <p class="text-danger pt-5 font-size-28 text-center font-size-22 font-weight-bold">{{$t('userProfile.paymentErrorMsg')}}</p>
        <p class="text-danger pt-1 font-size-26 text-center font-size-22 font-weight-bold">{{paymentError}}</p>
        <div  class="d-flex justify-content-center align-items-center">
          <img :src="require('@/assets/animation/rejectPayment.svg')" class="w-300px" />
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
/*eslint-disable*/
// import Payment from '@/Utils/payment'
import { core } from "@/config/pluginInit";
import walletService from "@/modules/userProfile/services/userProfile";
import { veterinary, website } from "../../axios";
export default {
  data() {
    return {
      loading: true,
      status: "",
      paymentError: "",
      veterinaryRoute: localStorage.getItem("lastRoute"),
      merchant_transaction_id: sessionStorage.getItem(
        "merchant_transaction_id"
      ),
    };
  },
  methods: {
    async checkStatus() {
      this.loading = true;
      const initiateFromSessionStorage = JSON.parse(
        sessionStorage.getItem("initiateData")
      );
      const transactionId = initiateFromSessionStorage.transaction_id;
      const checkoutId = initiateFromSessionStorage.checkout_id;
      walletService
        .checkPaymentStatus({
          checkout_id: checkoutId,
          transaction_id: transactionId,
        })
        .then((res) => {
          this.status = res.data.status;
          if (!res.data.status) {
            this.paymentError = res.data.errors[0]
            core.showSnackbar('error', res.data.errors[0])
          }
          if (localStorage.getItem("lastRoute") === "veterinarian") {
            walletService
              .reservationStatus({
                status: this.status,
                checkout_id: checkoutId,
                reservation_id: this.merchant_transaction_id,
              })
              .then((res) => {
                sessionStorage.removeItem("merchant_transaction_id");
              })
              .catch((err) => {
                console.error("Error in reservationStatus:", err.response.data);
              });
          }
        })
        .catch(() => {
          this.status = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    core.index();
  },
  created() {
    this.checkStatus();
  },
};
</script>
<style></style>
